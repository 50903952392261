import { useEffect } from 'react';
import { setListingDataToRedirectAfterAuth } from '../../util/localStorage';
import config from '../../config';
import { createSlug } from '../../util/urlHelpers';
const { listingTypeRider, listingTypeHorse, userTypeRider } = config;
/**
 * Set listing data in case there is listingId search param;
 *
 * The data is then used in order to redirect
 * a user to the listing (horse or rider) page after auth
 */
export const useSetListingDataToStorage = listingData => {
    const listingId = listingData?.id?.uuid;

    useEffect(() => {
        if (!listingId) return;

        const { type, userRepresentationId } = listingData.attributes.publicData;

        const isRiderListing = type === listingTypeRider;
        const isHorseListing = type === listingTypeHorse;

        if (isRiderListing) {
            setListingDataToRedirectAfterAuth({
                name: 'ProfilePage',
                params: {
                    id: userRepresentationId || listingData?.author?.id?.uuid,
                    userType: userTypeRider,
                },
            });
        }

        if (isHorseListing) {
            setListingDataToRedirectAfterAuth({
                name: 'ListingPage',
                params: { id: listingId, slug: createSlug(listingData) },
            });
        }
    }, [listingId]);
};

import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { RIDER_AVAILABILITY_CONFIRMED } from '../../../../marketplace-custom-config';
import { FormattedMessage } from '../../../../util/reactIntl';
import {
    getActionButtonStateFromTx,
    TRANSACTION_STATES_STATUS_ACCEPTED,
    TRANSACTION_STATES_STATUS_DECLINED,
    TRANSACTION_STATES_STATUS_ENQUIRED,
    txIsAccepted,
    txIsDeclined,
    txIsEnquired,
} from '../../../../util/transaction';
import { ensureCurrentUser, ensureUser } from '../../../../util/data';
import { NamedLink } from '../../../../components';
import { IconEyeL } from '../../../../icons';
import { createResourceLocatorString } from '../../../../util/routes';
import config from '../../../../config';
import routeConfiguration from '../../../../routeConfiguration';
import ActionButton from './ActionButton';
import css from './SectionContact.css';

const { userTypeHorseowner } = config;

const getCurrentListingTransactions = listing => transaction =>
    transaction.relationships.listing?.data?.id?.uuid === listing?.id?.uuid ||
    transaction.attributes.protectedData.listingSubstitutionId === listing?.id?.uuid;

export default ({
    loading,
    currentUser,
    riderListing,
    isOwnListing,
    listingAuthor,
    currentListing,
    authorDisplayName,
    userIsCurrentUser,
    isAnonymousListing,
    setInquiryModalOpen,
    setExternalListingModalOpen,
}) => {
    const history = useHistory();
    const { location } = history;
    /** Redux store data */
    const allTransactions = useSelector(s => s.marketplaceData.entities.transaction);

    const listingTransactions =
        typeof allTransactions === 'object'
            ? Object.values(allTransactions).filter(
                  getCurrentListingTransactions(currentListing),
                  {}
              )
            : {};

    const {
        attributes: { state: currentListingState },
        id: { uuid: listingId },
    } = currentListing;

    const {
        attributes: {
            profile: {
                publicData: { userType: currentUserType, availabilityStatus, profileComplete },
            },
        },
    } = currentUser;

    const { externalListing } = currentListing.attributes.publicData || {};
    const { text: actionButtonText, state: actionButtonState } = getActionButtonStateFromTx(
        isOwnListing ? [] : listingTransactions
    );
    const ensuredUser = userIsCurrentUser
        ? ensureCurrentUser(listingAuthor)
        : ensureUser(listingAuthor);

    const { publicData: userPublicData } = ensuredUser.attributes.profile;

    const currentUserId = currentUser?.id?.uuid;

    const redirectToSignUpPage = () => {
        const { pathname, search, hash } = location;
        const state = { from: `${pathname}${search}${hash}` };

        // We need to log in before showing the modal, but first we need to ensure
        // that modal does open when user is redirected back to this listingpage
        history.push(
            createResourceLocatorString(
                'SignupRider',
                routeConfiguration(),
                {},
                listingId ? { listingId } : {}
            ),
            state
        );
    };

    const onContactUser = () => {
        if (!currentUser || !currentUserId) {
            return redirectToSignUpPage();
        }

        if (!!externalListing) {
            return setExternalListingModalOpen(true);
        }

        if (isOwnListing) {
            return;
        }
        /**
         * Navigate a rider to an existing chat even if he is not available
         */
        const isDeclined = actionButtonState === TRANSACTION_STATES_STATUS_DECLINED;
        const isAccepted = actionButtonState === TRANSACTION_STATES_STATUS_ACCEPTED;
        const isEnquired = actionButtonState === TRANSACTION_STATES_STATUS_ENQUIRED;

        if (isDeclined || isAccepted || isEnquired) {
            const declinedOrAcceptedTx = listingTransactions.find(
                tx => txIsDeclined(tx) || txIsAccepted(tx) || txIsEnquired(tx)
            );

            history.push(
                createResourceLocatorString(
                    'OrderDetailsPage',
                    routeConfiguration(),
                    { id: declinedOrAcceptedTx.id.uuid },
                    {}
                )
            );
        }

        setInquiryModalOpen(true);
    };

    const isClosed = currentListingState === 'closed';
    const currentUserIsOwner = currentUserType === userTypeHorseowner;
    const currentUserAvailable =
        availabilityStatus && availabilityStatus === RIDER_AVAILABILITY_CONFIRMED;
    /**
     * horseowner navigates on the horse listing page
     */
    const ownersView = !isOwnListing && currentUserIsOwner && !userIsCurrentUser;
    /**
     * handle cases when user's availability is set to confirmed
     * but rider listing has not been opened yet
     */
    const riderListingReopeningInProgress =
        currentUserAvailable && profileComplete && !riderListing;

    const resolveActionId = () => {
        const ownersViewId =
            !isOwnListing &&
            currentUserIsOwner &&
            !userIsCurrentUser &&
            'horseownerViewsHorseownersProfile';

        const isClosedId = !ownersViewId && isClosed && 'noPublishedListingsForRider';

        return ownersViewId || isClosedId;
    };

    const actionDisabed =
        isClosed || ownersView || isOwnListing || loading || riderListingReopeningInProgress;

    const actionButtonProps = {
        loading,
        riderListing,
        actionDisabed,
        listingAuthor,
        profileComplete,
        currentUserAvailable,
        text: actionButtonText,
        state: actionButtonState,
        displayName: authorDisplayName,
        actionId: resolveActionId(),
        className: classNames(css.contactButton, css.contactButtonDesktop),
        onContactUser,
    };

    const equiryNotAllowed = isClosed || isOwnListing;
    const equiryNotAllowedId = () =>
        isClosed
            ? 'contactAuthorButtonListingClosed'
            : isOwnListing
            ? 'inquiryDisabledForOwnListings'
            : 'default';

    return (
        <div className={css.buttonContainer}>
            {/* Desktop */}
            {equiryNotAllowed && (
                <p className={classNames([css.desktopOnly, css.notAllowed])}>
                    <FormattedMessage id={`ListingPage.${equiryNotAllowedId()}`} />
                </p>
            )}
            <ActionButton {...actionButtonProps} />
            {isClosed ? null : (
                <footer className={css.contactButtonMobSection}>
                    {/* Mobile */}
                    {equiryNotAllowed && (
                        <p className={css.notAllowed}>
                            <FormattedMessage id={`ListingPage.${equiryNotAllowedId()}`} />
                        </p>
                    )}
                    <ActionButton {...actionButtonProps} className={css.contactButton} />
                </footer>
            )}

            {isAnonymousListing || !ensuredUser.id || isOwnListing ? null : (
                <NamedLink
                    className={css.linkButton}
                    name="ProfilePage"
                    params={{
                        id: ensuredUser.id.uuid,
                        userType: userPublicData.userType || 'rider',
                    }}
                >
                    <IconEyeL />
                    Profil ansehen
                </NamedLink>
            )}
        </div>
    );
};
